<template>
  <b-container fluid>
    <b-row class="justify-content-md-center px-2" style="display: flex;flex-direction: column;height: 100vh;align-items: center;justify-content: center;" v-if="isModalVisible" >
      <b-card text-variant="center" class="card card-congratulations" md="6">
        <!-- images -->
        <b-img :src="require('@/assets/images/elements/decore-left.png')" class="congratulations-img-left" />
        <b-img :src="require('@/assets/images/elements/decore-right.png')" class="congratulations-img-right" />
        <!--/ images -->

        <b-avatar variant="primary" size="70" class="shadow mb-2">
          <feather-icon size="28" icon="AwardIcon" />
        </b-avatar>
        <h1 class="mb-1 mt-50 text-white">
          Bienvenue su eCEV,
        </h1>
        <b-card-text class="m-auto w-75">
          Nous créons votre compte; un mail vous sera envoyé à la fin du processus à votre adresse mail: <strong>57.6%</strong>.
        </b-card-text>
        <button @click="onToggle" class="btn btn-primary">Retour à l'accueil</button>
      </b-card>
    </b-row>
  </b-container>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'

import {
  onMounted,
} from '@vue/composition-api'
import router from '@/router'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, password, image, ext, mimes,
} from '@validations'
// eslint-disable-next-line import/no-cycle
import VuePhoneNumberInput from 'vue-phone-number-input'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'animate.css'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BContainer,
  BInputGroup,
  BOverlay,
  BInputGroupAppend,
  BCard,
  BFormCheckbox,
  BButton, VBModal, BAlert,
  BAvatar,
  BImg,
  BCardText,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BInputGroupAppend,
    BInputGroup,
    BOverlay,
    BAvatar,
    BImg,
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BContainer,
    BAlert,
    BFormCheckbox,
    BButton,
    vSelect,
    VuePhoneNumberInput,
    // eslint-disable-next-line vue/no-unused-components

  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      required,
      password,
      email,
      confirmed,
      image,
      ext,
      mimes,
      isOpen: true
    }
  },
  setup() {

    onMounted(async () => {
      await getAbonnements()
      console.log(abonnements)
    })
    const submittedNames = []

    return {
      roles,
      telephonefull,
      abonnements,
      config,
      checkAbonnement,
      form,
      submittedNames,
      items,
      fields,
      storeUser,
      queuePurchase,
      getImage,
      removeItem,
      getClientAccess,
      piece,
      imageUrl,
      clearPiece,
      abonnementProcess,
      handleImageSelected,

    }
  },
  methods: {

    // bounce in method
    bounceIn() {
      this.$swal({
        title: 'Bounce In Animation',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    onToggle() {
      this.isOpen = !this.isOpen;
      router.push({ name: 'auth-login' })
    }
  },
}
</script>

<style scoped>
.font-size {
  font-size: 9 px !important;
}

.auth-wrapper {
  background-image: url(https://cdn.pixabay.com/photo/2022/01/30/14/55/wallet-6981329_1280.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* background-repeat: no-repeat; */
}
</style>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
